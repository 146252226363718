
import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { FormFile } from "@/components/forms/index";
import { HasDocuments } from "@/mixins/has-documents";
import { bus } from "@/main";

@Component({
    components: {
        documentDeleteModal: () => import("@/components/modals/delete-document.vue"),
        updateDocumentModal: () => import("@/components/modals/update-document.vue"),
        uploadWhiseModal: () => import("@/components/modals/upload-whise.vue"),
        previewMediaModal: () => import("@/components/modals/preview-media.vue"),
        documentGrid: () => import("@/components/document/grid.vue"),
        FormFile,
    },
})
export default class DossierTabDocuments extends Mixins(HasDocuments) {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("document/all") documents!: Document[];

    @Prop({ default: true }) canEdit!: boolean;
    @Prop({ default: true }) canSearch!: boolean;

    @Ref() documentGridRef!: any;

    /**
     * -----------------
     * Private variables
     * -----------------
     */

    search = "";

    bus = bus;

    async mounted() {
        await this.getActivityDocuments(Number(this.$route.params.id));
    }

    handlePushToWhise(documents: Document[]) {
        bus.$emit("show-upload-whise", this.activity.id, documents);
    }

    handleDocumentDeleted(documentId: number) {
        if (!this.documentGridRef) {
            return;
        }

        const index = this.documentGridRef.selectedFiles.findIndex((d: Document) => d.id === documentId);

        if (index === -1) {
            return;
        }

        this.documentGridRef.handleFileDeSelected(this.documents[index], index);
    }
}
